.pfBorderBox {
	border: 1px solid rgba(128, 128, 128, 0.194) !important; }


.pfColorito {
	background-color: #edf2da; }


.redBar {
	background-color: #ffe3da !important; }

.redProgressBar {
	background-color: var(--rf-stat-reserved) !important; }

.pinkRemaindTime {
	background-color: #fec5b4 !important; }

.pekinio {
	background-color: #e7efcf; }

.mui {
	background-color: var(--rf-stat-available); }

.btn-md:focus {
	box-shadow: none !important; }


.blueFont {
	color: var(--rf-main-color) !important;
	font-size: 16px !important; }

#localVideo {
	border: 1px solid var(--rf-white); }

.f12 {
	font-size: 12px !important; }


#videoMeeting_userInfo span a {
	text-decoration: none;
	color: #000; }


#videoMeeting_userInfo {
	flex-grow: 1; }


#remoteVideo {
	background-size: fit-content;
	z-index: 99;
	background-color: #111111; }


#remoteVideo video {
	height: 100% !important;
	width: 100% !important;
	min-height: 100% !important;
	min-width: 100% !important;
	width: auto;
	height: auto;
	z-index: 100;
	background-size: cover;
	object-fit: cover;
	overflow: hidden;
	border-top-left-radius: 1px;
	border-top-right-radius: 1px;
	border: none !important; }


.invertColor {
	filter: invert(100%) !important; }

.isPressed {
	filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%) !important; }


.pfBarColor {
	background-color: #323333;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px; }

.barElem {
	position: relative;
	z-index: 888; }

.barElem::after {
	content: " ";
	height: 75%;
	width: 0;
	position: absolute;
	left: 0;
	top: 15%;
	border-left: 2px solid rgba(252, 252, 252, 0.568);
	filter: invert(100%) !important;
	opacity: 0.6; }

.barElem:nth-child(5)::after {
	border-left: none !important; }


.inputSourceContainer {
	background: rgba(56, 54, 54, 0.357);
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	z-index: 1000; }


.fullScreen {
	position: absolute;
	height: 100vh;
	width: 100vw;
	z-index: 9995; }


.btp {
	max-height: 30px; }

.skewit {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover; }


.closeFc {
	z-index: 9999 !important;
	top: 5%;
	left: 50%;
	position: absolute; }


.closeFSIcon {
	background-image: url("./ico-close.svg");
	background-repeat: no-repeat;
	background-size: 35px 35px;
	height: 35px;
	width: 35px;
	filter: invert(100%);
	cursor: pointer; }

.removePointer {
	pointer-events: none; }

.pointer {
	cursor: pointer; }

.withEvents {
	pointer-events: auto; }


.skipPadding {
	padding: 18px !important;
	padding-inline-end: 26px !important; }


.puff-in-center {
	-webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
	animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both; }


/* ----------------------------------------------
 * Generated by Animista on 2022-1-3 13:44:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-in-center {
	0% {
		-webkit-transform: scale(2);
		transform: scale(2);
		-webkit-filter: blur(4px);
		filter: blur(4px);
		opacity: 0; }

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1; } }


@keyframes puff-in-center {
	0% {
		-webkit-transform: scale(2);
		transform: scale(2);
		-webkit-filter: blur(4px);
		filter: blur(4px);
		opacity: 0; }

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1; } }



.visually-hidden {
	background-image: url("./VideoDisplayController/polyWhite.svg") !important; }


.videoListItem {
	position: relative;
	text-align: left !important; }

.videoListItem:hover {
	background-color: rgb(33, 33, 33) !important;
	color: var(--rf-text-light) !important;
	cursor: pointer; }


.rightVideoContainer {
	max-width: 40% !important; }


#agentPanel .card-body {
	border: none !important;
	background-color: #f5f5f556 !important; }

#agentPanel .card {
	border: none !important;
	background-color: #f5f5f556 !important; }

