#page_404 {
    width: 98% !important;
    max-width: unset !important;
    color: var(--rf-black);
    img {
        max-height: 150px; }
    h1 {
        font-size: 7rem; }

    .cta404 .col {
        max-width: 20% !important; } }
