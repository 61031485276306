.toast-header {
    background-color: var(--rf-main-color)!important;
    color: var(--rf-text-light) !important;
    position: relative; }
.toast-close {
    opacity: 1 !important;
    position: absolute !important;
    margin-left: unset !important;
    margin-right: unset !important;
    &:hover {
        -webkit-animation-name: spin_anim  !important;
        -webkit-animation-duration: 1000ms !important;
        -webkit-animation-iteration-count: 1 !important;
        -moz-animation-name: spin_anim !important;
        -moz-animation-duration: 1000ms !important;
        -moz-animation-iteration-count: 1 !important;
        -ms-animation-name: spin_anim !important;
        -ms-animation-duration: 1000ms !important;
        -ms-animation-iteration-count: 1 !important;
        animation-name: spin_anim !important;
        animation-duration: 1000ms !important;
        animation-iteration-count: 1 !important; } }

