.videoButton {
    width: 50%;
    color: var(--rf-text-light) !important; }


.leftButtonBorder {
    border-left: 1px solid var(--rf-white); }


.timeBar {
    height: 50px; }


.may {
    height: 5px; }

