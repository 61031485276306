#loadingModal .modal-content {
    background-color: transparent !important;
    border: none !important;
    height: 100vh !important; }


.modal-backdrop {
    z-index: 9999999  !important; }

.modal-backdrop.show {
    opacity: .9 !important; }

.modal-content {
    border: 0 !important; }

.modal {
    z-index: 99999 !important; }

.costumeModalBG {
    background-color: transparent !important;
    border: none !important; }

.fade.modal.show {
    z-index: 9999999999 !important; }

#loadingModal .sideImageLG {
    width: 100% !important;
    text-align: center; }

#loadingModal .sideImageLG > div {
    width: 25px !important;
    height: 25px !important;
    background-color: var(--rf-white);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }


#loadingModal .sideImageLG .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }


#loadingModal .sideImageLG .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s; }


.onTopModal {
    z-index: 99999999999 !important; }
