#message_bar {
    height: 60px;
    background-color: var(--rf-body-bg); }
.breadcrumb-item {
    white-space: nowrap; }


@media (max-width: 800px) {
    html[dir='rtl'] {
        #apartment_reserve__breadcrumbs {
            position: relative;
            width: 100%;
            &:after {
                content: '';
                position: absolute;
                left: -15px;
                top: 0;
                z-index: 9999;
                height: 100%;
                width: 60px;
                background: var(--rf-light-fadout-gradient); } } }
    html[dir='ltr'] {
        #apartment_reserve__breadcrumbs {
            position: relative;
            width: 100%;
            &:after {
                content: '';
                position: absolute;
                right: -15px;
                top: 0;
                z-index: 9999;
                height: 100%;
                width: 60px;
                background: var(--rf-light-fadout-gradient-revers); } } } }


html[dir='rtl'] {
    #crumbsContainer {
        overflow-x: auto;
        padding-left: 40px; }
    #apartment_reserve__breadcrumbs {
        height: 100%;
        ol {
            --bs-breadcrumb-divider: ''-; }
        .breadcrumb-item {
            background-color: var(--rf-breadcrumb-lev1);
            height: 100%;
            display: flex;
            align-items: center;
            color: var(--rf-text-light);
            font-weight: 600;
            text-decoration: none;
            position: relative;
            z-index: 555;
            &:after {
                content: "";
                width: 0;
                height: 0;
                top: 0;
                bottom: 0;
                position: absolute;
                background-color: transparent;
                left: -14px;
                border-top: 30px solid transparent;
                border-bottom: 30px solid transparent;
                border-right: 15px solid var(--rf-breadcrumb-lev1); }
            a {
                color: var(--rf-text-light);
                font-weight: 600;
                text-decoration: none; }

            &:nth-child(2) {
                z-index: 550;
                background-color: var(--rf-breadcrumb-lev2);
                &:after {
                    border-right: 15px solid var(--rf-breadcrumb-lev2); } }

            &:nth-child(3) {
                z-index: 549;
                background-color: var(--rf-breadcrumb-lev3);
                &:after {
                    border-right: 15px solid var(--rf-breadcrumb-lev3); } }

            &:nth-child(4) {
                z-index: 548;
                background-color: var(--rf-breadcrumb-lev4);
                &:after {
                    border-right: 15px solid var(--rf-breadcrumb-lev4); } }

            &:nth-child(5) {
                z-index: 545;
                background-color: var(--rf-breadcrumb-lev5);
                &:after {
                    border-right: 15px solid var(--rf-breadcrumb-lev5); } }

            &:nth-child(6) {
                z-index: 544;
                background-color: var(--rf-breadcrumb-lev6);
                &:after {
                    border-right: 15px solid var(--rf-breadcrumb-lev6); } }
            &:nth-child(7) {
                z-index: 543;
                background-color: var(--rf-breadcrumb-lev7);
                &:after {
                    border-right: 15px solid var(--rf-breadcrumb-lev7); } }
            &:nth-child(8) {
                z-index: 538;
                background-color: var(--rf-breadcrumb-lev8);
                &:after {
                    border-right: 15px solid var(--rf-breadcrumb-lev8); } }
            &:nth-child(9) {
                z-index: 532;
                background-color: var(--rf-breadcrumb-lev9);
                &:after {
                    border-right: 15px solid var(--rf-breadcrumb-lev9); } } } } }


html[dir='ltr'] {

    #crumbsContainer {
        overflow-x: auto;
        padding-right: 40px; }
    #apartment_reserve__breadcrumbs {
        height: 100%;
        ol {
            --bs-breadcrumb-divider: ''; }
        .breadcrumb-item {
            background-color: var(--rf-breadcrumb-lev1);
            height: 100%;
            display: flex;
            align-items: center;
            color: var(--rf-text-light);
            font-weight: 600;
            text-decoration: none;
            position: relative;
            z-index: 555;
            &:after {
                content: "";
                width: 0;
                height: 0;
                top: 0;
                bottom: 0;
                position: absolute;
                background-color: transparent;
                right: -14px;
                border-top: 30px solid transparent;
                border-bottom: 30px solid transparent;
                border-left: 15px solid var(--rf-breadcrumb-lev1); }
            a {
                color: var(--rf-text-light);
                font-weight: 600;
                text-decoration: none; }

            &:nth-child(2) {
                z-index: 550;
                background-color: var(--rf-breadcrumb-lev2);
                &:after {
                    border-left: 15px solid var(--rf-breadcrumb-lev2); } }

            &:nth-child(3) {
                z-index: 549;
                background-color: var(--rf-breadcrumb-lev3);
                &:after {
                    border-left: 15px solid var(--rf-breadcrumb-lev3); } }

            &:nth-child(4) {
                z-index: 548;
                background-color: var(--rf-breadcrumb-lev4);
                &:after {
                    border-left: 15px solid var(--rf-breadcrumb-lev4); } }

            &:nth-child(5) {
                z-index: 545;
                background-color: var(--rf-breadcrumb-lev5);
                &:after {
                    border-left: 15px solid var(--rf-breadcrumb-lev5); } }

            &:nth-child(6) {
                z-index: 544;
                background-color: var(--rf-breadcrumb-lev6);
                &:after {
                    border-left: 15px solid var(--rf-breadcrumb-lev6); } }
            &:nth-child(7) {
                z-index: 543;
                background-color: var(--rf-breadcrumb-lev7);
                &:after {
                    border-left: 15px solid var(--rf-breadcrumb-lev7); } }
            &:nth-child(8) {
                z-index: 538;
                background-color: var(--rf-breadcrumb-lev8);
                &:after {
                    border-left: 15px solid var(--rf-breadcrumb-lev8); } }
            &:nth-child(9) {
                z-index: 532;
                background-color: var(--rf-breadcrumb-lev9);
                &:after {
                    border-left: 15px solid var(--rf-breadcrumb-lev9); } } } } }


@keyframes addBanner {
	0% {
		opacity: 0; }

	100% {
		opacity: 1; } }


.addBanner:last-child:not() {
	animation: addBanner 1s ease-in-out; }

.addBanner {
	transition: all 1s ease-in-out; }

