#apartment_reserve__step-1 {
    display: flex;
    .buildings_list__item {
        border: 3px solid transparent;
        border-radius: 4px;
        transition: all .3s ease-in-out;
        color: var(--rf-text-dark) !important;
        cursor: pointer;
        .building_stats,
        .building_info {}

        .buildings_list__item-img {
            border-radius: 5px; } } }

#buildingImgCont-sticky-wrapper,
#floorImg-sticky-wrapper,
#aptSideImg-sticky-wrapper, {
    width: 50% !important;
    min-width: 540px;
    max-width: 540px;
    .step_side_img {
        max-width: 540px !important; }
    &:not(.is-sticky) {
        .step_side_img {
            width: unset !important; } } }

@media (min-width: 1500px) and (max-width: 1700px) {
    #buildingImgCont-sticky-wrapper,
    #floorImg-sticky-wrapper,
    #aptSideImg-sticky-wrapper, {
        min-width: 400px;
        max-width: 400px;
        .step_side_img {
            max-width: 400px !important; } } }

@media (min-width: 1360px) and (max-width: 1500px) {
    #buildingImgCont-sticky-wrapper,
    #floorImg-sticky-wrapper,
    #aptSideImg-sticky-wrapper, {
        min-width: 350px;
        max-width: 350px;
        .step_side_img {
            max-width: 350px !important; } } }

@media (max-width: 1360px) {
    #buildingImgCont-sticky-wrapper,
    #floorImg-sticky-wrapper,
    #aptSideImg-sticky-wrapper, {
        display: none; } }

@media (max-width: 1000px) {
    #apartment_reserve__step-1 {
        .buildings_list__item.blueBorder {
            padding-bottom: 60px !important; }
        .buildings_list__item {
            .buildings_list__iteminfo {
                padding-right: 0 !important; } } } }

