#apartment_reserve__step-5 {
    flex-wrap: nowrap;
    .step5_content {
        max-width: 60%; }
    .apartment_list__item {
        border: 3px solid transparent;
        border-radius: 4px;
        transition: all .3s ease-in-out;
        position: relative;
        cursor: pointer;
        .apartment_titles {
            display: flex;
            align-items: center; }


        .apartment_tumb {
            border-radius: 4px;
            width: 200px;
            height: 140px;
            object-fit: cover;
            object-position: center center; }
        .apartment_description {
            color: var(--rf-text-dark);
            font-size: 18px; }

        .old_price {
            text-decoration: line-through;
            opacity: .5;
            display: inline-block;
            margin-left: 10px; } }

    .apartment_list__item.verticalView {
        flex-flow: column !important;
        width: 100%;
        .appartmentShort_image {
            img {
                width: 100%; } }
        .appartmentShort_content {
            margin-top: 1rem;
            .info_container {
                flex-direction: column !important; } } }


    .bg-image {
        img:first-child {
            max-height: calc(100vh - 190px); } }

    #sigSideContainer-sticky-wrapper {
        padding: 0 !important;
        max-width: 40%;
        min-width: unset; } }

@media (max-width: 1000px) {
    .step5_content {
        max-width: unset !important; } }
@media (max-width: 550px) {
    #apartment_reserve__step-5 {
        .apartment_list__item {
            flex-flow: column-reverse;
            #sigModal {
                img {
                    width: 100%; } } } } }

.bolder {
	font-weight: 500;
	font-size: 16px !important; }


.pfFontTitle {
	font-size: 42px !important;
	font-weight: 800;
	text-shadow: 1px 1px 1px rgba(150, 150, 150, 1); }


.pfItemFont {
	font-weight: 500 !important;
	font-size: 17px !important; }

.pfItemFontSm {
	font-size: 15px !important;
	font-weight: 600; }


.pfAvailText {
	font-weight: 500;
	font-size: 18px;
	text-shadow: 1px 1px 2px rgba(150, 150, 150, 0.33); }

.pfMeetingBarFont {
	font-size: 14px !important;
	font-weight: 600;
	color: rgba(67, 67, 67, 0.941) !important; }


.pfColorGray {
	color: rgba(67, 67, 67, 0.941) !important; }

.pfInput {
	padding: 13px !important; }


.pfShadow {
	-webkit-box-shadow: 0 0 9.5px 0.5px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 9.5px 0.5px rgba(0, 0, 0, 0.1); }

.pfMdFont {
	font-size: 22px !important;
	font-weight: 700 !important; }

.pfLgFont {
	font-size: 48px !important;
	font-weight: 700 !important; }


.pfContainerShadow {
	-webkit-box-shadow: 0px 0px 5px 3px rgba(69, 69, 69, 0.131);
	box-shadow: 0px 0px 5px 3px rgba(69, 69, 69, 0.131); }

.pfHeaderColor {
	color: rgba(0, 0, 0, 0.754) !important; }


.pfBorder {
	border: 1px solid #111111d2; }


.pfIcon svg {
	background-color: #212a4d; }

.pfIcon {
	opacity: 0.8 !important;
	filter: grayscale(60%) !important; }


.pfLine {
	background-color: rgba(67, 67, 67, 0.487) !important;
	height: 1.5px !important; }


.pfHeader {
	font-size: 50px !important; }


.pfThumb {
	height: 140px !important;
	width: 200px !important; }


.pfOldPrice {
	color: rgba(65, 65, 65, 0.864);
	font-weight: 500; }


.pfPlaceholder::placeholder {
	opacity: 0.7 !important; }

.pfSelect {
	opacity: 0.7 !important;
	font-size: 16px !important; }



.pfSuccessM {
	margin-top: 25%; }



@media (min-width: 1500px) {
	.xlfont {
		font-size: 18px !important; } }



@media (min-width: 1200px) {
	.pfSuccessM {
		margin-top: 10%; }

	.pfFloorBborder {
		font-size: 13px !important; } }



@media (min-width: 768px) {
	.pfSuccessM {
		margin-top: 2%; } }



@media (min-width: 500px) {
	.pfSuccessM {
		margin-top: 2%; } }


.fadeInside {
	animation: fade 1.5s ease !important; }

.pfFloorBborder {
	border-bottom: 1.5px solid rgba(65, 65, 65, 0.097) !important; }


.pfActiveControl:not(.skipPadding) {
	background-color: var(--rf-main-color)  !important;
	opacity: 1 !important;
	color: var(--rf-text-light) !important;
	cursor: auto !important; }


.maxZindex {
	z-index: 9999 !important; }


.locationImg {
	position: absolute !important;
	top: 0; }


@keyframes fade {
	0% {
		opacity: 0.5; }

	100% {
		opacity: 1; } }




.buildings_list__itemExtra {
	z-index: 999 !important;
	background-color: transparent;
	/* opacity: 0 */ }

.showElement {
	opacity: 1 !important; }

.blueBorder {
	border: 2px solid var(--rf-main-color) !important;
	border-radius: 5px; }


/* ----------------------------------------------
 * Generated by Animista on 2021-12-17 13:22:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */

.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }


@-webkit-keyframes slide-in-left {
	0% {
		-webkit-transform: translateX(-100px);
		transform: translateX(-100px);
		opacity: 0; }

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1; } }


@keyframes slide-in-left {
	0% {
		-webkit-transform: translateX(-100px);
		transform: translateX(-100px);
		opacity: 0; }

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1; } }




.aptPrevFont {
	font-size: 21px !important; }



.formItemFont {
	font-size: 15px !important;
	font-weight: 500 !important; }


.sigButtonPadding {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	letter-spacing: 1px !important; }


#cleanSig:hover {
	background-color: rgb(175, 175, 175);
	transform: scale(1.1); }

#cleanSig {
	transition: all 1s ease-in-out;
	color: grey !important;
	background: unset !important; }


html[dir='rtl'] .closepopupButton {
	position: absolute !important;
	left: 15px !important;
	top: 5px !important;
	cursor: pointer !important; }

html[dir='ltr'] .closepopupButton {
	position: absolute !important;
	right: 15px !important;
	top: 5px !important;
	cursor: pointer !important; }

html[dir='rtl'] .moveleft {
	right: 0%; }


html[dir='ltr'] .moveleft {
	left: 0%; }


#contractContent {
	min-height: 60vh !important; }

#contract_preview__container {
	min-height: 60vh !important; }

