.findAgent__main_container {
	display: flex;
	flex-flow: column;

	.findAgentDemo_bg {
		img {
			height: 310px;
			width: 100%;
			object-fit: cover {
            object-position: center center; } } }

	.findAgent__welcome_actions {
		display: flex;
		flex-flow: column;
		a {
			letter-spacing: 1px;
			z-index: 1; } } }
.checkListStyle {
	list-style: none;
	padding: 0;
	li {
		&::before {
			content: '✓';
			color: var(--rf-main-color); } } }




html[dir='rtl'] {
	#agentFormBack {
		transform: rotate(180deg);
		top: 10px;
		left: 10px; }
	.checkListStyle {
		li {
			&::before {
				padding-left: 1em; } } } }

html[dir='ltr'] {
	#agentFormBack {
		transform: rotate(180deg);
		top: 10px;
		right: 10px; }
	.checkListStyle {
		li {
			&::before {
				padding-right: 1em; } } } }

#agentFormBack:hover {
	-webkit-animation-name: spin_anim;
	-webkit-animation-duration: 1000ms;
	-webkit-animation-iteration-count: 1;
	-moz-animation-name: spin_anim;
	-moz-animation-duration: 1000ms;
	-moz-animation-iteration-count: 1;
	-ms-animation-name: spin_anim;
	-ms-animation-duration: 1000ms;
	-ms-animation-iteration-count: 1;
	animation-name: spin_anim;
	animation-duration: 1000ms;
	animation-iteration-count: 1; }


@keyframes spin_anim {
	from {
		transform: rotate(0deg); }
	to {
		transform: rotate(360deg); } }
@-moz-keyframes spin_anim {
	from {
		-moz-transform: rotate(0deg); }
	to {
		-moz-transform: rotate(360deg); } }
@-webkit-keyframes spin_anim {
	from {
		-webkit-transform: rotate(0deg); }
	to {
		-webkit-transform: rotate(360deg); } }



#searchingBar {
	background: transparent;
	height: 4px;
	display: none; }
#progBar, #progBar_m {
	transition: all 1s linear;
	height: 4px;
	width: 0;
	background: var(--rf-main-color);
	display: block; }
#searchTimeBar {
	color: var(--rf-text-light);
	display: flex;
	flex-flow: column;
	align-items: center; }

#timeBar {
	height: 4px;
	background: rgba(var(--rf-white-code), .4);
	display: block; }




.highlight_comp {
	z-index: 9999999 !important; }
