.blueBG {
	background-color: var(--rf-main-color) !important;
	color: var(--rf-text-light) !important;
	height: 35px !important;
	border-radius: 5px !important; }


.agentPanelBtn {
	all: unset;
	position: absolute;
	top: 25px;
	left: 0;
	right: 0;
	left: 0;
	right: 0;
	width: 30px;
	margin-left: auto;
	margin-right: auto;
	border: none !important {
    border-radius: 5px; } }

.agentPanelBtn:hover {
    transition: all 0.5s ease-in-out;
    transform: scale(1.1); }

.topBorder {
	border-top: 2px solid var(--rf-white) !important; }

.dcollp {
	display: block !important; }
