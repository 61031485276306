#apartment_reserve__step-3__graphic {
    #building_slider {
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
        position: relative; }
    .building_slide {
        min-width: 300px; }
    .building_title {
        background: var(--rf-title-bg);
        margin: 0;
        padding: 8px 15px; }
    .apartments_list {
        border-radius: unset;
        .apartment_list__item {
            padding: 0.8rem;
            cursor: pointer;
            border-left: 0;
            border-right: 0;
            .floor_title {
                width: 50px;
                margin-bottom: 0; } } } }

