#project_home_slider {
    .slide {
        height: 80vh;
        img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
            height: 80vh; } }

    // Arrows
    .control-arrow {
        height: 100%;
        width: 50px;
        font-size: 3.5rem;
        display: flex;
        z-index: 999999 !important;
        align-items: center;
        justify-content: center;
        top: 0;
        opacity: .2;
        transition: all .3s ease-in-out;
        &::before {
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; }

        &:hover {
            opacity: 1; } }
    .control-prev {
        background: var(--rf-dark-fadout-gradient);
        left: 0;
        right: unset;
        &::before {
            border-right: 10px solid var(--rf-white); } }
    .control-next {
        right: 0;
        background: var(--rf-dark-fadout-gradient-revers);
        left: unset;
        &::before {
            border-left: 10px solid var(--rf-white); } }
    .control-disabled {
        cursor: not-allowed;
        opacity: .2; } }
