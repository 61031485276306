.popoverContainer {
	max-width: 100% !important;
	max-width: 425px !important;
	z-index: 9999 !important;
	border: 3px solid var(--rf-main-color) !important;
	max-height: 225px !important;
	min-height: 120px !important; }


@media (max-width: 450px) {
	.popoverContainer {
		width: calc(100% - 4rem) !important; } }



.popoverHead {
	background: none !important;
	border: none !important; }


.sm_aptStatus {
	height: 26px;
	font-size: 12px !important;
	align-self: center;
	font-weight: 500;
	white-space: nowrap; }


.minFontSize {
	font-size: 18px !important; }


.old_price {
	text-decoration: line-through !important;
	opacity: 0.5; }

.smHeader {
	font-size: 26px !important;
	align-self: center !important; }

.innerpopoverloader {
	width: 100% !important;
	margin-top: 40px; }
