@import 'sass/Variables.scss';
@import 'sass/Colors';
@import 'sass/Directions';
@import 'sass/Fonts';
@import 'sass/FontsSet';
@import 'sass/Buttons';
@import 'sass/Arrows';
@import 'sass/Slick';
@import 'sass/Tabs';
@import 'sass/Form';
@import 'sass/Accessability';
@import 'sass/Loaders';

*, *::before, *::after {
  box-sizing: border-box; }

html {
  scroll-behavior: smooth;
  height: 100%;
  margin: 0;
  direction: rtl; }

.dirltr {
  direction: ltr !important; }


.dirrtl {
  direction: rtl !important; }

.imgObjFitCoverCenter {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  object-position: center center; }

.display-linebreak {
  white-space: pre-line; }

body {
  margin: 0;
  min-height: 100%;
  font-family: 'Assistant' !important;
  color: var(--rf-text-dark) !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
  transition: all .3s ease-in-out; }

.bg-image {
  padding: unset !important;

  img:first-child {
    width: 100%;
    height: 100%;
    min-height: 900px;
    object-fit: cover;
    object-position: center center; } }

.step_side_img {
  display: block;
  width: 50%;
  img:first-child {
    max-height: calc(100vh - 150px); }
  #directionImg {
    top: calc(100vh - 150px - 165px) !important;
    border-radius: 5px;
    img {
      min-height: unset !important; } } }



.step_content {
  width: 100%; }

@media (max-width: 1365px) {
  .step_side_img {
    display: none !important; }
  .step_content {
    width: 100% !important; } }

.files_download_container {
  display: flex;
  flex-flow: column;
  .file_item {
    text-decoration: none;
    color: var(--rf-main-color);
    margin: 8px 0;
    img {
      height: 25px; } } }
.lockFields {
  pointer-events: none !important; }



.vid_devices_list {
  max-height: 120px;
  overflow-y: auto; }

body > iframe {
  z-index: -999999999;
  display: none !important; }

.grecaptcha-badge {
  display: none !important; }


.mobileView,
.mobileFlexView {
    display: none !important; }
.desktopView {
    display: flex !important; }

@media (max-width: 1150px) {
    .mobileTextCenter {
      text-align: center; }
    .mobileContentCenter {
      justify-content: center !important; }
    .mobileFuulWidth {
      width: 100%; }
    .mobileFuulWidthi {
      width: 100% !important; }
    .mobileFlexView {
      display: flex !important; }
    .mobileView {
        display: block !important; }
    .desktopView {
        display: none !important; } }


.iti.iti--container {
  z-index: 99999999999; }

.cursor-pointer {
  cursor: pointer; }

*:focus {
  outline: none !important; }
.form-control:focus {
  box-shadow: 0 0 9.5px 0.5px rgba(var(--rf-black-code), .1) !important; }
.accordion-button:focus {
  box-shadow: inset 0 -1px 0 rgba(var(--rf-black-code), 0.13); }


.rf-no-hover:hover {
  color: inherit !important; }
