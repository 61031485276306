#apartment_reserve__step-8 {
    min-height: 80vh !important; }


.zindexon {
    z-index: 9999 !important; }

.zindexoff {
    z-index: 1000 !important; }


#conffetiCanvas {
    min-height: 75vh !important; }
