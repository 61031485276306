.nav-tabs {
    .nav-item {
        .nav-link {
            background: none;
            border-radius: 0;
            border: 0;
            border-bottom: 4px solid transparent;
            font-weight: bold;
            color: var(--rf-black); }
        .nav-link.active {
            background: none;
            border-radius: 0;
            border-bottom: 4px solid var(--rf-main-color); } } }
