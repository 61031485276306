.donutCostume {
	max-height: 70px !important;
	max-width: 70px !important; }

.donutTooltipEl {
	background: rgba(var(--rf-black-code), .7);
	border-radius: 3px;
	color: var(--rf-text-light);
	opacity: 1;
	pointer-events: none;
	position: absolute;
	transform: translate(-50%, 0);
	transition: all .1s ease;
	z-index: 9999999; }
