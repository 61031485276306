form {
    .input-group-phone {
        .form-select {
            flex: unset;
            width: 100px; } } }


.iti input[type=tel] {
    padding-left: 3rem !important; }

button:disabled,
input[type=submit]:disabled {
    cursor: not-allowed;
    pointer-events: all !important; }

.activeVideoControllItem {
    background: rgba(var(--rf-main-color-code), .3) !important; }



.form-check-input:checked {
    background-color: var(--rf-main-color) !important;
    border-color: var(--rf-main-color) !important; }

.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--rf-main-color-code),.1) !important; }
