#login_page {
    min-height: calc(100vh - 210px); }
#sideImageLogin {
    width: unset;
    overflow: hidden;
    img {
        height: calc(100vh - 75px); } }

#login_page {
    #sideImageLogin-sticky-wrapper {
        padding: 0;
        width: 60% !important;
        &:not(.is-sticky) {
            .login_side_img {
                width: unset !important; } } } }

@media (max-width: 1150px) {
    #login_page {
        #sideImageLogin-sticky-wrapper {
            display: none; } } }
.loginLayout_img {
    height: 110px; }

@keyframes sswitchPage {
	0% {
		opacity: 1; }

	100% {
		opacity: 0; } }



.switchpage {
	animation: sswitchPage 1s ease-in-out !important; }
