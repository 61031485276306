@font-face {
    font-family: 'Assistant';
    font-weight: 300;
    src: url('fonts/Assistant-Light.ttf'); }


@font-face {
    font-family: 'Assistant';
    font-weight: 400;
    src: url('fonts/Assistant-Regular.ttf'); }

@font-face {
    font-family: 'Assistant';
    font-weight: 500;
    src: url('fonts/Assistant-Medium.ttf'); }

@font-face {
    font-family: 'Assistant';
    font-weight: 600;
    src: url('fonts/Assistant-SemiBold.ttf'); }


@font-face {
    font-family: 'Assistant';
    font-weight: 700;
    src: url('fonts/Assistant-Bold.ttf'); }

@font-face {
    font-family: 'Assistant';
    font-weight: 800;
    src: url('fonts/Assistant-ExtraBold.ttf'); }

