html[dir='rtl'] {
	.floor_filters {
		display: flex;
		#roomFilter {
			text-align: right; }

		.dropdown-toggle::after {
			color: var(--rf-black) !important;
			vertical-align: 10% !important;
			position: absolute;
			left: 10px;
			margin-right: unset !important;
			top: calc(50%); }
		.dropdown-menu {
			text-align: right; } } }

#roomFilter {
	border: 0;
	background: var(--rf-white); }
html[dir='ltr'] {
	.floor_filters {
		display: flex;
		#roomFilter {
			text-align: left; }

		.dropdown-toggle::after {
			color: var(--rf-black) !important;
			vertical-align: 10% !important;
			position: absolute;
			right: 10px;
			margin-left: unset !important;
			top: calc(50%); }
		.dropdown-menu {
			text-align: left;

			// li
			// 	background-image: url("./ico-rooms.svg") !important
			// 	background-repeat: no-repeat
			// 	background-position: bottom left
			// 	padding-right: 30px
 } } }			// 	background-size: 10px !important

.floor_filters {
	max-width: 200px; }
