#apartment_reserve__step-7 {
    min-height: 80vh; }

#register_page {
    .login_side_img {
        width: unset !important; } }

#register_page {
    #sideImageLogin-sticky-wrapper {
        width: 60%; } }

@media (max-width: 1150px) {
    #register_page {
        #sideImageLogin-sticky-wrapper {
            display: none; }
        #register_pageContent {
            min-height: calc(100vh - 210px);
            width: 100% !important; } }

    // #main_nav
    //     .navbar-toggler
 }    //         display: none !important

@media (max-width: 1400px) {
    #apartment_reserve__step-7 {
        #meetingImg {
            display: none !important; } } }

.smaller_bg {
    width: calc(60% - 120px) !important; }
