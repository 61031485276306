.autocomplete_text {
    position: relative;
    input {
        width: 100%; }
    ul.suggestions_container {
        list-style: none;
        li {
            border-bottom: 1px solid rgba(var(--rf-black-code), .1); } }
    .suggestions_container {
        position: absolute;
        z-index: 8999999;
        background: var(--rf-white);
        width: 100%;
        max-height: 300px;
        overflow-y: auto; }

    .suggestion-active {
        font-weight: 600;
        background: rgba(var(--rf-black-code), .1); } }
