
.rf-fullBtn {
    width: 100%; }

.rf-btnIcon {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center; }

.rf-btnPrimary {
    color: var(--rf-text-light) !important;
    background-color: var(--rf-main-color) !important; }


.rf-btnSecondery {
    color: var(--rf-main-color) !important;
    background-color: var(--rf-white) !important;
    border: 1px solid var(--rf-main-color) !important; }
.btn-light {
  background-color: var(--rf-light) !important;
  border-color: var(--rf-light) !important; }

.rf-btn-primary {
    color: var(--rf-text-light) !important;
    background-color: rgba(var(--rf-main-color-code),.9)!important;
    border-color: rgba(var(--rf-main-color-code),.9)!important;
    transition: all .15s ease-in-out!important;
    &:hover {
        color: var(--rf-text-light) !important;
        background-color: rgba(var(--rf-main-color-code),1)!important;
        border-color: rgba(var(--rf-main-color-code),1)!important; } }

.dropdown-item.active,
.dropdown-item:active {
    color: var(--rf-text-light) !important;
    background-color: var(--rf-main-color) !important; }
