._access-menu._access {
  right: 90px !important;
  bottom: 10px !important; }
._access-icon._access {
  transition: all .3s ease-in-out;
  bottom: 10px !important;
  right: 10px !important;
  border-radius: 50% !important;
  background-color: var(--rf-main-color) !important; }
._access-menu._access,
._access-icon.material-icons._access {
  z-index: 9999991 !important; }

._access-menu._access.close {
  z-index: -9999 !important; }


html[dir='rtl'] {
  ul._access-scrollbar {
    direction: rtl;
    li {
      padding: 10px 30px 10px 0;
      svg {
        left: unset !important;
        right: 8px !important; }
      &::before {
        left: unset !important;
        right: 8px !important; } } } }


@media (max-width: 1100px) {
  ._access-icon._access {
    bottom: 70px !important; } }
