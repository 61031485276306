.titleFontSize {
	font-size: 54px; }


.pfButton {
	width: 136px;
	height: 50px; }


#aptScroller {
	-ms-overflow-style: none;
	scrollbar-width: none; }


#aptScroller::-webkit-scrollbar {
	display: none; }


.buildings_list__item-cta {
	z-index: 51 !important; }


html[dir='rtl'] .desktopView  .buildings_list__item-cta {
	position: absolute;
	left: 5px; }


html[dir='ltr'] .desktopView .buildings_list__item-cta {
	position: absolute;
	right: 5px; }


.mobileView  .buildings_list__item-cta {
	position: absolute;
	width: calc(100% - 16px) !important;
	bottom: 5px; }

.disappear {
	opacity: 0 !important;
	display: none !important; }


.showElem {
	opacity: 1 !important;
	transition: all 0.2s ease-in-out; }

.elemIndex {
	z-index: 50 !important; }

.notSelectedGrey:not(img) {
	opacity: 0.5 !important;
	filter: grayscale(50%) !important; }



.step_title {
	font-size: 42px; }


@media (max-width: 450px) {
	.step_title {
		font-size: 9.3vw; } }




.buildingList {
	min-height: 100vh !important;
	padding-left: 1rem;
	padding-right: 1rem; }


@keyframes flash {
	0% {
		opacity: 0.7; }

	100% {
		opacity: 1; } }



@keyframes flashOne {
	0% {
		opacity: 0.7; }

	100% {
		opacity: 1; } }


.flashit {
	-moz-animation: flash 1s ease-in-out;
	-webkit-animation: flash 1s ease-in-out;
	animation: flash 1s ease-in-out; }


@media (min-width: 1400px) and (max-width: 1600) {
	.buildings_list__item button {
		color: blue !important; } }



@media (max-width: 1000px) {
	.buildingList {
		padding-left: 0.5rem !important;
		padding-right: 0.5rem !important; } }


