#page_apartment_reserve,
#appContainer {
    min-height: 100vh !important; }

#page_apartment_reserve {
    overflow: hidden; }
#appContainer {
    transition: all 1s ease-in-out; }

html[dir='ltr'] #appContainer {
    padding: 0;
    padding-right: 8px; }


html[dir='rtl'] #appContainer {
    padding: 0;
    padding-left: 8px; }


.steps_content_container {
    width: calc(100% - 120px) !important;
    display: flex;
    flex-flow: column; }

@media (max-width: 1000px) {
    #steps_menu__col {
        display: none !important; }
    .steps_content_container {
        width: 100% !important; }

    #appContainer {
        padding-left: 0px !important;
        padding-right: 0px !important; } }
