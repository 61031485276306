#payment-form {
	.paymentLabel {
		font-weight: 500 !important;
		font-size: 18px !important;
		margin-top: 10px; }
	#payment-element,
	#submit,
	.payment_amount {
		width: 30vw;
		max-width: 400px;
		margin: 0 auto; }

	#payment-message__error {
		text-align: center;
		color: var(--rf-text-error);
		margin: 20px auto;
		font-weight: 500;
		font-size: 16px; }
	#payment-message__success {
		text-align: center;
		color: var(--rf-black);
		margin: 20px auto;
		font-weight: 500;
		font-size: 16px; } }


/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  	border-radius: 50%; }

.spinner {
	color: var(--rf-text-light);
	font-size: 22px;
	text-indent: -99999px;
	margin: 0px auto;
	position: relative;
	width: 20px;
	height: 20px;
	box-shadow: inset 0 0 0 2px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0); }

.spinner:before,
.spinner:after {
	position: absolute;
	content: ''; }

.spinner:before {
	width: 10.4px;
	height: 20.4px;
	background: var(--rf-main-color);
	border-radius: 20.4px 0 0 20.4px;
	top: -0.2px;
	left: -0.2px;
	-webkit-transform-origin: 10.4px 10.2px;
	transform-origin: 10.4px 10.2px;
	-webkit-animation: loading 2s infinite ease 1.5s;
	animation: loading 2s infinite ease 1.5s; }

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); }

	#apartment_reserve__step-6 {
		.pfItemFontSm {
			margin-right: 0.5rem; } } }
@media (max-width: 1000px) {
	#apartment_reserve__step-6 {
		.pfItemFontSm {
			margin-right: 0 !important;
			margin-left: 0 !important; } } }
@media (max-width: 850px) {
	#payment-form {
		#payment-element,
		#submit,
		.payment_amount {
			width: 100%;
			max-width: 400px;
			margin: 0 auto; } } }
