#apartment_reserve__step-2 {
    min-height: 100vh !important;
    .floors_list {
        .floors_list__item {
            border: 3px solid transparent;
            border-radius: 4px;
            transition: all .3s ease-in-out;
            color: var(--rf-text-dark) !important;
            cursor: pointer;

            .floors_list__item-cta {
                white-space: nowrap;
                transition: opacity .3s ease-in-out;
                width: 125px;
                height: 35px;
                padding: 2px 6px;
                align-self: center; } } } }

#floorScroll {
	-ms-overflow-style: none;
	scrollbar-width: none; }

#floorScroll::-webkit-scrollbar {
	display: none; }


.floors_list__item-cta {
	opacity: 1; }


.floorInfoFont {
	font-size: 18px !important;
	font-weight: bold !important;
	color: var(--rf-text-dark) !important;
	width: fit-content !important; }


.floorButtonFont {
    font-size: 18px !important;
    font-weight: bold !important; }

.costumeFloorMargin {
    margin-right: 15px !important; }

@media (min-width: 1400px) and (max-width: 1600px) {
    .costumeFloorMargin {
        margin-right: 0 !important;
        margin-left: 2%; } }

@media (max-width: 560px) {
    .floors_list__item,
    .floors_list__item.blueBorder {
        flex-flow: column !important; }
    .floors_list__item-cta {
	    width: calc(100%) !important {
        margin-top: 10px !important; } }
    .floors_list__item {
        .d-flex {
            margin-bottom: 0.5rem; } } }



.pfHeaderShadow {
	text-shadow: 1px 1px 1px var(--rf-dark-gray) !important; }


.visually-hidden {
	background-image: url("./selectRec.svg") !important;
	background-size: 10px !important; }


.floors_list {
	min-height: 70vh !important; }


.floors_list__item {
	padding-bottom: .5rem;
	padding-top: .5rem; }
