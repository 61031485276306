#meetingellm {
    position: fixed;
    width: 450px !important;
    top: 90px; }
#meetingellm-sticky-wrapper {
    width: 450px !important; }
#disabled_btns {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999999999999 !important; }
.videoCom {
    border: 2px solid transparent; }
.videoCom.agent-control {
    border: 2px solid var(--rf-main-color);
    border-radius: 5px; }

.agentActionRow {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    p {
        width: 80px; }
    button {
        flex: 1; } }

.controlButtons:not(.pfActiveControl) {
    background: var(--rf-body-bg); }
#agentControlBanner {
    position: absolute;
    top: 0;
    background: var(--rf-main-color);
    color: var(--rf-text-light);
    z-index: 999;
    font-size: 14px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    left: 50%;
    transform: translate(-50%, 0%); }
#meetingellm__toggle {
    position: fixed;
    z-index: 999999;
    top: calc(50% - 25px / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 75px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    color: var(--rf-main-color);
    background: var(--rf-white);
    font-size: 18px;
    border-color: var(--rf-white);

    cursor: pointer;
    transition: all 1s ease-in-out;
    text-decoration: none;
    padding: 0;
    p {
        display: none;
        font-size: 16px;
        line-height: 20px !important; }
    i {
        display: block;
        transition: all 1s ease-in-out; } }


html[dir='rtl'] {
    #meetingellm__toggle {
        left: 450px;
        right: unset;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px; } }
html[dir='ltr'] {
    #meetingellm__toggle {
        left: unset;
        right: 450px;
        i {
            transform: rotate(180deg); } } }

.toggle_open {
    left: 0 !important;
    right: unset; }

html[dir='rtl'] .toggle_open {
    left: 0 !important;
    border-radius: 0 5px 5px 0;
    right: unset !important;
    i {
        transform: rotate(180deg) !important; } }

html[dir='ltr'] .toggle_open {
    right: 0 !important;
    left: unset !important;
    border-radius: 5px 0 0 5px;
    i {
        transform: rotate(0deg) !important; } }


#meetingellm__target {
    opacity: 1;
    background: var(--rf-white);
    padding: 0 1em 1em;
    margin-right: 0;
    transition: all .3s ease-in-out; }
#meetingellm__target.hidden {
    opacity: 0;
    margin-right: -200px; }

.devices_list {
    max-height: 150px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin-bottom: 0; }

@media (max-width: 1100px) {
    #meetingellm__toggle {
        display: none !important; } }
