#videoMenuButton {
	all: unset;
	position: absolute;
	top: -10px;
	left: 50%;
	transform: translateX(-50%);
	background-color: transparent;
	z-index: 100 !important {
    cursor: pointer;
    transition: all 0.3s ease-in-out; } }


.videoButtonContainer {
	background-color: var(--rf-white)fff;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px; }

#videoMenuCollapse .card-body {
	border: none !important;
	background-color: transparent !important;
	height: 50px !important; }


#videoMenuCollapse .card {
	border: none !important;
	background-color: transparent !important; }


.blueText {
	color: var(--rf-main-color) !important; }

.noBordeRaius {
	border-radius: unset !important; }

.videoMenu {
    max-height: 65px !important;
    transition: all 0.3s ease-in-out !important; }

.borderRb {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    transition: all 0.3s ease-in-out !important; }

.bgTrans {
	background-color: transparent !important; }

.bgTrans .visually-hidden {
	background-image: url("../VideoDisplayController/polyWhite.svg") !important;
	background-size: 10px !important; }


.buttonActive {
	top: 8px !important; }


.pointer:hover {
	transition: all 0.3s ease-in-out;
	transform: scale(1.1); }
