html[dir='rtl'] {
    .alert {
        img[role=icon] {
            height: 30px;
            padding-right: 10px; } }
    #timeSetBar {
        direction: ltr; } }

html[dir='ltr'] {
    .alert {
        img[role=icon] {
            height: 30px;
            padding-right: 10px; } } }

.alert.alert-red {
    background: var(--rf-alert-red);
    border-radius: 0;
    text-align: center;
    font-weight: bold;
    color: var(--rf-black); }
.alert.alert-blue {
    background: var(--rf-alert-blue);
    border-radius: 0; }

