#main_nav {
    background: var(--rf-white) !important;
    height: 74px !important;
    z-index: 9999999;
    flex-wrap: nowrap;
    justify-content: flex-start;


    button.navbar-toggler {
        color: var(--rf-main-color);
        font-size: 2rem;
        display: none; }
    .navbar-toggler:focus,
    .navbar-toggler:active,
    .navbar-toggler-icon:focus {
        outline: none;
        box-shadow: none; }

    .nav-section-title {
        color: var(--rf-scrollbar-thumb); } }

html[dir='rtl'] {
    #main_nav {
        .mobile_schedualMeeting {
            text-decoration: none;
            i {
                margin-left: 0.5rem; } } } }

html[dir='ltr'] {
    #main_nav {
        .mobile_schedualMeeting {
            text-decoration: none;
            i {
                margin-right: 0.5rem; } } } }

#main_nav__mobile {
    background: var(--rf-white) !important;
    height: calc(100vh - 50px);
    position: sticky;
    overflow-y: auto;
    hr {
        width: 95% !important; } }

@media (min-width: 1150px) {
    #main_nav__mobile {
        display: none !important; }
    #main_nav__desktop {
        display: flex !important; } }
@media (max-width: 1150px) {
    button.navbar-toggler:not(.d-none) {
        display: block !important; }
    #main_nav {
        flex-wrap: wrap !important; } }

@media (max-width: 1150px) {
    #main_nav__desktop {
        display: none !important; } }

@media (max-width: 450px) {
    .mobile_schedualMeeting {
        i {
            margin-right: 0 !important;
            margin-left: 0 !important; }
        span {
            display: none; } } }



.mobileAgent__container {
    #mobileAgent__dropdown {
        background: unset;
        background: transparent !important;
        border-radius: unset;
        border: unset;
        color: var(--rf-black) !important;
        &:after {
            margin: 0 8px;
            left: unset !important;
            right: 5px !important;
            vertical-align: 0.15em !important;
            top: 17px;
            color: var(--rf-main-color); }
        &:hover {
            background: transparent; }

        img {
            height: 30px; } }
    #mobileAgent__dropdown + ul {
        li:first-child {
            position: relative;
            &:after {
                position: absolute;
                bottom: 0;
                height: 1px;
                width: 90%;
                left: 5%;
                opacity: .3;
                background: var(--rf-black);
                content: ''; } } } }
html[dir='rtl'] {
    .mobileAgent__container {
        .dropdown-menu[data-bs-popper] {
            right: -20px; }
        ul {
            li {
                a,
                button {
                    text-align: right !important; } } } } }
html[dir='ltr'] {
    .mobileAgent__container {
        ul {
            li {
                a,
                button {
                    text-align: left !important; } } } } }


@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; }

    17% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; } }


@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; }

    17% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in; }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out; } }

.heartbeat {
    -webkit-animation: heartbeat 2s ease-in-out 2 both;
    animation: heartbeat 2s ease-in-out 2 both; }
