.apartment-additional-details {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .apartment-additional-details__item {
        display: flex;
        flex-flow: row;
        // text-align: center
        img {
            width: 25px;
            max-height: 25px;
            filter: invert(53%) sepia(13%) saturate(414%) hue-rotate(179deg) brightness(91%) contrast(86%); } } }
