#notesContent {
    max-height: 150px !important;
    overflow-y: auto; }

#notesAccordion,
.accordion-item {
    border: 0px !important; }

#notesList_trigger {
    &:after {
        margin-right: 0 !important;
        margin-left: auto; } }
.accordion-button:not(.collapsed) {
    background: transparent !important;
    color: var(--rf-black) !important; }

#add_note_form {
    border-radius: 5px;
    display: flex;
    flex-flow: row;
    border: 1px solid rgba(var(--rf-black-code), .15); }

.noteList_note {
    background: rgba(var(--rf-white-code) , 0.36);
    margin: 5px 0;
    padding: 15px 20px;
    border-radius: 5px; }

.err_msg {
    display: none;
    color: var(--rf-text-error);
    font-size: 16px; }
