
#login_form__verification {
    input[type=text] {
        background: none;
        border-radius: 0;
        border: 0;
        border-bottom: 2px solid #d8d8d8;
        text-align: center; } }

html[dir='ltr'] {
    #verificationInputs {
        flex-flow: row-reverse; } }
