#apartment_reserve__step-7 {
    .stepCon {
        max-width: 60%; }
    .bg-image {
        width: 40%; } }
@media (max-width: 1400px) {
    #apartment_reserve__step-7 {
        .stepCon {
            max-width: unset !important; }
        .bg-image {
            display: none; } } }
