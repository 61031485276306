#steps_menu__container {
    max-width: 120px;
    min-width: 120px; }
#steps_menu__container,
#steps_menu__mobile_container {
    display: block;
    width: auto;
    .steps_menu__link {
        line-height: 16px;
        opacity: .5;
        background-color: unset;
        border-radius: 0;
        border: none;
        &:hover {
            transform: unset !important; }
        img {
            filter: invert(53%) sepia(13%) saturate(414%) hue-rotate(179deg) brightness(91%) contrast(86%); } }

    .steps_menu__link.current-link {
        opacity: 1;
        box-shadow: inset 0px 0px 4px 1px rgba(var(--rf-black-code),0.2);
        img {
            filter: invert(58%) sepia(98%) saturate(7040%) hue-rotate(213deg) brightness(92%) contrast(92%); } }

    .steps_menu__link.complate-link {
        opacity: 1;
        color: var(--rf-text-darkest);
        position: relative;
        img {
            filter: invert(58%) sepia(98%) saturate(7040%) hue-rotate(213deg) brightness(92%) contrast(92%); } } }

html[dir='rtl'] {
    #steps_menu__container,
    #steps_menu__mobile_container {
        .steps_menu__link {
            border-right: 5px solid transparent; }
        .steps_menu__link.current-link {
            border-right: 5px solid var(--rf-main-color); } } }
html[dir='ltr'] {
    #steps_menu__container,
    #steps_menu__mobile_container {
        .steps_menu__link {
            border-left: 5px solid transparent; }
        .steps_menu__link.current-link {
            border-left: 5px solid var(--rf-main-color); } } }

#steps_menu__mobile_container {
    width: unset;
    .display-linebreak {
        white-space: unset !important; }

    .steps_menu__link {
        flex-direction: row !important;
        width: unset;
        br {
            display: none; } } }

@media (max-width: 1150px) {
    #steps_menu__container {
        display: none !important; }
    #steps_menu__mobile_container {
 } }        // display: block !important

.doneStep {
    &:after {
        content: "\2713";
        color: var(--rf-text-light);
        font-size: 12px;
        font-weight: 600;
        position: absolute;
        top: 25px;
        left: 30%;
        width: 20px;
        height: 20px;
        border: 3px solid var(--rf-white);
        background-color: var(--rf-alert-success);
        border-radius: 50%; } }

#steps_menu__mobile_container {
    .doneStep {
        &:after {
            left: unset !important;
            top: 30px;
            right: 30px; } } }
