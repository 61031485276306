.slick-dots {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    li {
        padding: 1px 0;
        border-radius: 50%;
        display: grid;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        button {
            background: var(--rf-main-color);
            opacity: .3;
            border: none;
            height: 15px;
            width: 15px;
            margin: 0 7px;
            border-radius: 50%;
            text-indent: -9999em;
            transition: all 0.3s ease-in-out; } }


    li.slick-active {
        padding: 0;
        button {
            opacity: 1;
            background: var(--rf-main-color); } } }
.apartment_preview_gallery .slick-dots ~ .apartment_preview_gallery__slide {
    display: none !important; }
#gallery.tab-pane {
    .slick-dots {
        padding-bottom: 15px; } }
