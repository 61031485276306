#apartment_view_change__desktop,
#apartment_view_change__mobile {
    button {
        border: 1px solid var(--rf-gray);
        display: flex;
        align-items: center;
        img {
            height: 20px;
            display: inline-block; }
        &:focus {
            box-shadow: unset !important; } } }


html[dir='rtl'] {
    #apartment_view_change__desktop,
    #apartment_view_change__mobile {
        button {
            img {
                margin-left: 8px; } } } }


html[dir='ltr'] {
    #apartment_view_change__desktop,
    #apartment_view_change__mobile {
        button {
            img {
                margin-right: 8px; } } } }

#view3DViewdiv {
    &:hover {
        color: var(--rf-text-light) !important; } }
.activeView {
    border: 1px solid var(--rf-main-color) !important;
    background-color: var(--rf-main-color) !important;
    color: var(--rf-text-light) !important; }

@media (max-width: 1150px) {
    #view3DViewButton {
        padding-right: 0.75rem !important; }
    #apartment_view_change__desktop,
    #apartment_view_change__mobile {
        max-width: unset !important;
        .row {
            flex-flow: column;
            button {
                border: 0;
                padding-right: 0; } } } }
