.detailsApprovalModalContainer {
    min-width: 620px; }

.detailsApprovalModalContainer #approveModal {
    width: 200px; }


.detailsApprovalModalContainer #approveModal + div {
    width: unset !important;
    flex: unset !important; }


.detailsApprovalModalContainer #approveModal + div .apartment_titles {
    margin-bottom: 0.5rem !important; }

.detailsApprovalModalContainer #approveModal + div .info_container +  .info-item {
    margin-bottom: 0.5rem !important;
    margin-top: unset !important; }


.detailsApprovalModalContainer #approveModal + div .info_container .info-item {
    margin-bottom: 0.5rem !important; }


.detailsApprovalModalContainer  .apartment_list__item {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    width: 100%; }

.detailsApprovalModalContainer #approveModal + div .info-item {
    font-size: 16px !important; }


@media screen and (min-width:1400) and (max-width: 992px) {
    body {
        background-color: var(--rf-main-color); } }

@media screen and (max-width: 1000px) {
    #approveModalImage {
        display: none; }

    .approveModalBody {
        width: 100% !important; }

    .detailsApprovalModalContainer #approveModal + div .info-item img {
        height: 20px; }



    .detailsApprovalModalContainer {
        min-width: unset !important; } }

