#map {
    height: 50vh;
    position: relative;
    padding: 0 !important;
    .markerrr {
        border: 4px solid var(--rf-main-color);
        padding: 1.5rem 2.5rem !important;
        position: relative;
        border-radius: 8px;
        background: var(--rf-white);
        box-shadow: 0 2px 7px 1px rgba(var(--rf-black-code), 0.3);
        &:after {
            background: linear-gradient(45deg,var(--rf-white) 50%, rgba(var(--rf-black-code),0) 51%, rgba(var(--rf-black-code),0));
            box-shadow: -2px 2px 2px 0 rgba(var(--rf-black-code),.2);
            content: "";
            height: 15px;
            left: 50%;
            position: absolute;
            bottom: -18px;
            transform: translate(-50%,-50%) rotate(-45deg);
            width: 15px;
            border-left: 4px solid var(--rf-main-color);
            border-bottom: 4px solid var(--rf-main-color); } }
    .gm-style-iw-d {
        padding: 0 !important;
        overflow: unset !important; } }
