.stats_container {
    .stat-item {
        position: relative;
        font-size: 1rem;
        padding-right: 0px; }

    .stat_circle {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%; }
    .stat_circle__available {
        background: var(--rf-stat-available);
        -webkit-box-shadow: 0px 0px 2px 0px var(--rf-stat-available);
        box-shadow: 0px 0px 2px 0px var(--rf-stat-available); }
    .stat_circle__progress {
        background: var(--rf-stat-progress);
        -webkit-box-shadow: 0px 0px 2px 0px var(--rf-stat-progress);
        box-shadow: 0px 0px 2px 0px var(--rf-stat-progress); }
    .stat_circle__reserved {
        background: var(--rf-stat-reserved);
        -webkit-box-shadow: 0px 0px 2px 0px var(--rf-stat-reserved);
        box-shadow: 0px 0px 2px 0px var(--rf-stat-reserved); }
    .stat_value,
    .stat_title {
        font-weight: 600; } }


html[dir='rtl'] {
    .stats_container {
        .stat_circle,
        .stat_title,
        .stat_value {
            margin-right: 5px; } }
    #main_nav__desktop {
        .stats_container {
            max-width: 550px;
            margin: 0 10px;
            .row .col {
                flex-flow: row; } } } }
html[dir='ltr'] {
    .stats_container {
        .stat_circle,
        .stat_title,
        .stat_value {
            margin-left: 5px; } }
    #main_nav__desktop {
        .stats_container {
            max-width: 550px;
            margin: 0 10px;
            .row .col {
                flex-flow: row; } } } }


.info_container {
    .info-item {
        display: flex;
        align-items: center;
        font-weight: 600;
        img {
            width: 25px; } } }

.floor-stats {
    display: flex;
    align-items: center;
    span {
        display: inline-block;
        width: 18px;
        height: 22px;
        margin: 0 2px;
        border-radius: 4px; }
    .floor-stat__available {
        background: var(--rf-stat-available); }
    .floor-stat__progress {
        background: var(--rf-stat-progress); }
    .floor-stat__reserved {
        background: var(--rf-stat-reserved); } }

html[dir='rtl'] {
    .apartment_status {
        padding: 2px 15px;
        border-radius: 30px;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        & > span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: -5px;
            margin-left: 5px; } } }

html[dir='ltr'] {
    .apartment_status {
        padding: 2px 15px;
        border-radius: 30px;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        & > span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-left: -5px;
            margin-right: 5px; } } }


.apartment_status.apartment_status__available {
    background: rgba(var(--rf-stat-available-code), .2);
    span {
        background: rgba(var(--rf-stat-available-code), 1); } }

.apartment_status.apartment_status__progress {
    background: rgba(var(--rf-stat-progress-code), .2);
    span {
        background: rgba(var(--rf-stat-progress-code), 1); } }

.apartment_status.apartment_status__reserved {
    background: rgba(var(--rf-stat-reserved-code), .2);
    span {
        background: rgba(var(--rf-stat-reserved-code), 1); } }

@media (max-width: 1300px) {
    #main_nav__desktop {
        #project_stats_container {
            .nav-section-title {
                white-space: break-spaces !important; }
            .statItem {
                flex-wrap: wrap;
                justify-content: center;
                white-space: inherit; } } } }
@media (max-width: 1150px) {
    #project_stats_container {
        background: var(--rf-body-bg);
        width: 100% !important;
        max-width: unset !important;
        .row {
            flex-flow: column; } } }
