:root {

    // START - Theme Colors

    // main-color
    --rf-main-color: #1562E3;
    --rf-main-color-code: 21, 98, 227;

    // secondary-color
    --rf-secondary-color: #1aa0d5;
    --rf-secondary-color-hsl-code: 197, 78%;
    
    // dark-bg
    --rf-dark-bg: #081d43;
    --rf-dark-bg-code: 8, 29, 67;

    // text-dark
    --rf-text-dark: #081d43;
    // text-light
    --rf-text-light: #fff;
    // text-darkest
    --rf-text-darkest: #000;

    // END - Theme Colors

    
    // General Colors
    --rf-white: #fff;
    --rf-white-code: 255, 255, 255;
    --rf-light: #e6e6e6;
    --rf-gray: #ccc;
    --rf-dark-gray: #808080;
    --rf-dark-gray-code: 128, 128, 128;
    --rf-black: #000;
    --rf-black-code: 0, 0, 0;

    // Elements BG
    --rf-body-bg: #f3f8fe;
    --rf-scrollbar-thumb: #62667f;
    --rf-scrollbar-thumb-code: 98, 102, 127;

    --rf-title-bg: #d8d8d8;
    --rf-title-bg-code: 216, 216, 216;


    // Gradients
    --rf-light-fadout-gradient: linear-gradient(-90deg, rgba(var(--rf-white-code), 0) 0%, rgba(var(--rf-white-code), 0.8) 50%); //calc
    --rf-light-fadout-gradient-revers: linear-gradient(90deg, rgba(var(--rf-white-code), 0) 0%, rgba(var(--rf-white-code), 0.8) 50%); //calc

    --rf-dark-fadout-gradient: linear-gradient(-90deg, rgba(var(--rf-black-code),0) 0%, rgba(var(--rf-black-code),.4 ) 88%); //calc
    --rf-dark-fadout-gradient-revers: linear-gradient(90deg, rgba(var(--rf-black-code),0) 0%, rgba(var(--rf-black-code),.4 ) 88%); //calc

    // breadcrumb
    --rf-breadcrumb-lev1:  hsl(var(--rf-secondary-color-hsl-code), 47%);
    --rf-breadcrumb-lev2:  hsl(var(--rf-secondary-color-hsl-code), 50%);
    --rf-breadcrumb-lev3:  hsl(var(--rf-secondary-color-hsl-code), 55%);
    --rf-breadcrumb-lev4:  hsl(var(--rf-secondary-color-hsl-code), 60%);
    --rf-breadcrumb-lev5:  hsl(var(--rf-secondary-color-hsl-code), 65%);
    --rf-breadcrumb-lev6:  hsl(var(--rf-secondary-color-hsl-code), 70%);
    --rf-breadcrumb-lev7:  hsl(var(--rf-secondary-color-hsl-code), 75%);
    --rf-breadcrumb-lev8:  hsl(var(--rf-secondary-color-hsl-code), 80%);
    --rf-breadcrumb-lev9:  hsl(var(--rf-secondary-color-hsl-code), 85%);

    // Stats
    --rf-stat-available: #7dab00;
    --rf-stat-progress: #fbb03b;
    --rf-stat-reserved: #fd3a00;
    --rf-stat-available-code: 125, 171, 0;
    --rf-stat-progress-code: 251, 176, 59;
    --rf-stat-reserved-code: 253, 58, 0;


    // Alerts
    --rf-alert-success: #7dab00;
    --rf-alert-red: #fec3b1;
    --rf-alert-blue: #dff1fb;
    --rf-text-error: #df1b41;
}