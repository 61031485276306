#apartment_reserve__step-3__list {
    min-height: 100vh !important;
    .apartments_list {
        .apartment_list__item {
            border: 3px solid transparent;
            border-radius: 4px;
            transition: all .3s ease-in-out;
            position: relative;
            .apartment_titles {
                display: flex;
                align-items: center;
                width: 64% !important;
                .apartment_status {
                    margin: 0 !important; } }


            .apartment_tumb {
                border-radius: 4px;
                width: 200px;
                height: 150px;
                object-fit: cover;
                object-position: center center; }
            .apartment_description {
                color: var(--rf-text-dark);
                font-size: 18px; }

            .old_price {
                text-decoration: line-through;
                opacity: .5;
                display: inline-block;
                margin-left: 10px; }

            .mobileView {
                position: relative;
                .apartment_titles {
                    width: 100% !important;
                    justify-content: space-between;
                    align-items: center; }

                .apartment_tumb {
                    max-width: 400px;
                    width: 100% !important;
                    height: 100% !important; } } } } }



#aptSideImg-sticky-wrapper {
    &:not(.is-sticky) {
        .step_side_img {
            position: relative !important; } } }

html[dir='rtl'] {
    #directionImg {
        height: 150px;
        width: 150px;
        position: absolute;
        left: 10px; } }

html[dir='ltr'] {
    #directionImg {
        height: 150px;
        width: 150px;
        position: absolute;
        right: 10px; } }

@media (max-width: 1000px) {
    #apartment_reserve__step-3__list {
        .apartments_list {
            .apartment_list__item.blueBorder {
                padding-bottom: 60px !important;

                .mobileView .pfAptButton {
                    height: 50px !important;
                    width: 100% !important;
                    z-index: 100 !important;
                    box-shadow: 0 0 9.5px 0.5px rgba(var(--rf-black-code), 0.1);
                    cursor: pointer;
                    position: absolute;
                    bottom: -40px !important;
                    top: unset !important;
                    left: 0 !important;
                    right: 0 !important; } } } } }

.pfAptMargin {
	margin-top: 1.5% !important; }


.aptStatusFont {
	font-size: 12px !important; }


#clickableApt {
	z-index: 88 !important;
	background-color: transparent !important;
	position: absolute !important; }


.pfAptButton {
	height: 50px !important;
	width: 135px !important;
	z-index: 100 !important;
	box-shadow: 0 0 9.5px 0.5px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	position: absolute;
	top: 5px !important;
	left: 5px !important; }


html[dir='ltr'] .pfAptButton {
	right: 5px !important; }


html[dir='rtl'] .pfAptButton {
	left: 5px !important; }


.pfAptFont {
	font-size: 16px !important; }


@media (min-width: 1400px) and (max-width: 1570px) {

	.apartment_tumb {
		width: 140px !important;
		height: 120px !important; }


 #aptScroller {
	 min-height: 100vh; } }


