html[dir='rtl'] {
    #apartment_reserve__step-4 {
        #confirm_apartment_selection {
            left: 1rem; }
        .apartment_prices {
            .old_price {
                margin-left: 10px; } } } }

html[dir='ltr'] {
    #apartment_reserve__step-4 {
        #confirm_apartment_selection {
            right: 1rem; }
        .apartment_prices {
            .old_price {
                margin-right: 10px; } } } }

#apartment_reserve__step-4 {
    #confirm_apartment_selection {
        position: absolute;
        top: -1.4rem; }
    .apartment_prices {
        display: flex;
        align-items: center;
        img {
            width: 25px; }
        .old_price {
            text-decoration: line-through;
            opacity: .5;
            display: inline-block; } }
    .info_container {
        .info-item {
            img {
                height: 50px;
                width: unset; } } }

    .apartment-additional-details {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        .apartment-additional-details__item {
            display: flex;
            flex-flow: row;
            img {
                width: 25px;
                max-height: 25px;
                filter: invert(53%) sepia(13%) saturate(414%) hue-rotate(179deg) brightness(91%) contrast(86%); } } }
    #apartment-details-tabs {
        flex-wrap: nowrap;
        overflow-y: auto;
        padding-right: 30px !important; }
    /* width */
    ::-webkit-scrollbar {
        width: 0px;
        height: 3px; }
    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent; }


    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(var(--rf-scrollbar-thumb-code), .31); }


    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--rf-scrollbar-thumb); }

    #apartment-details-tabs__trigger_container {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            right: -15px;
            top: 0;
            z-index: 9999;
            height: 100%;
            width: 80px;
            background: var(--rf-light-fadout-gradient-revers); } }
    #apartment-details-tabs__content {
        #gallery {
            padding-top: 20px;
            .slider.animated {
                align-items: center; }
            .carousel.carousel-slider {
                max-width: 1140px;
                margin: 0 auto;
                .slide {
                    img {
                        width: 100%;
                        max-width: 1140px;
                        max-height: 554px; }
                    iframe {
                        margin: 0 !important;
                        width: 100%; } }
                // Arrows
                .control-arrow {
                    height: 100%;
                    width: 40px;
                    font-size: 3.5rem;
                    display: flex;
                    z-index: 999999 !important;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    opacity: .4;
                    transition: all .3s ease-in-out;
                    &:hover {
                        opacity: 1; } }
                .control-prev {
                    background: var(--rf-dark-fadout-gradient);
                    left: 0;
                    right: unset; }
                .control-next {
                    right: 0;
                    background: var(--rf-dark-fadout-gradient-revers);
                    left: unset; }
                .control-disabled {
                    cursor: not-allowed;
                    opacity: .2; } }

            .carousel {
                .thumbs-wrapper {
                    .control-arrow.control-prev,
                    .control-arrow.control-next {
                        display: none; } }
                .thumbs {
                    display: flex;
                    justify-content: center;
                    .thumb.selected {
                        border-color: var(--rf-main-color);
                        border-radius: 5px; }
                    .thumb {
                        width: 126px !important;

                        img {
                            height: 84px;
                            width: 100% !important;
                            object-fit: cover;
                            object-position: center center; } } } } }
        #plans,
        #downloads {
            a {
                color: var(--rf-main-color); } } }
    .aptMainImg {
        max-height: 500px;
        height: 100%;
        width: 100%;
        max-width: 750px;
        object-fit: contain;
 }        // object-position: center center

    .fileDownloadLink {
        text-decoration: none;
        display: flex;
        flex-flow: column;
        align-items: center;
        color: var(--rf-black);
        img {
            height: 50px; } }

    #aptPlans {
        a {
            text-decoration: none;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            img {
                height: 25px; } }
        .aptPlansImage {
            padding: 0.5rem;
            object-fit: fill;
            object-position: center center;
            max-height: 540px;
            width: 100%; } } }

.featureItem {
    width: 120px !important;
 }    // min-height: 25% !important

.carousel-root {
    .slide {
        z-index: unset !important; }
    .slide.selected {
        z-index: 99999 !important; } }

.react_player {
    height: 554px !important; }


@media (max-width: 1700px) {
    .react_player {
        height: 440px !important; } }

@media (max-width: 1500px) {
    .react_player {
        height: 360px !important; } }

@media (max-width: 1350px) {
    .react_player {
        height: 300px !important; } }

@media (max-width: 1100px) {
    .react_player {
        height: 400px !important; } }

@media (max-width: 850px) {
    .react_player {
        height: 360px !important; } }

@media (max-width: 700px) {
    .react_player {
        height: 280px !important; } }

@media (max-width: 550px) {
    .react_player {
        height: 240px !important; } }

@media (max-width: 450px) {
    .react_player {
        height: 220px !important; } }

@media (max-width: 400px) {
    .react_player {
        height: 180px !important; } }
@media (max-width: 1350px) {
    #confirm_apartment_selection {
        position: static !important;
        margin: 20px auto !important;
        width: 100% !important;
        display: block !important; }
    #confirm_apartment_selection2 {
        width: 100% !important; } }


@media (max-width: 1200px) {
    #confirm_apartment_selection2 {
        margin-left: auto; } }

@media (max-width: 700px) {
    #apartment-details-tabs__content {
        #gallery {
            .carousel {
                .thumbs-wrapper {
                    display: none; } } } } }



.pfPreviewFont {
    font-size: 48px !important;
    white-space: nowrap; }

#previewPrice {
    font-size: 30px !important; }

.descriptionFont {
    font-size: 18px !important; }

.previewHeadersFont {
    font-size: 24px !important; }


#previewSet {
    display: flex;
    flex-flow: row; }


@media (min-width: 1200px) and (max-width: 1330px) {
	.pfPreviewFont {
        font-size: 38px !important; } }



@media (max-width: 1200px) {
    #previewSet,#previewInfo,#previewPrice {
        justify-content: start !important; }


    #previewPrice {
        margin-top: .5rem; }


    html[dir='ltr'] #previewSet {
        margin-left: 1rem !important; }


    html[dir='rtl'] #previewSet {
        margin-right: 1rem !important; } }



@media (max-width: 1000px) {
    #previewSet {
        display: flex;
        flex-flow: column; }

    #apartmentDetails_content .apartment_prices span {
        font-size: 18px !important;
        font-weight: 700 !important; }

    #previewSet .info-item {
        align-self: start !important;
        flex-flow: row !important;
        flex-wrap: nowrap !important;
        margin-bottom: 1rem; }

    #previewSet .info-item img {
        height: 24px !important;
        width: auto !important;
        margin-bottom: 0 !important; } }



@media (max-width: 450px) {
    #previewInfo .step_title {
        font-size: 8.8vw !important; } }




.previewDonut {
    height: 50px !important;
    width: 50px !important; }
