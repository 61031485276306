.validationCustomMobile::placeholder {
	text-align: left !important;
	clear: both;
	padding-top: 3px;
	margin-left: 25px !important; }

.validationCustomMobile {
	min-width: 180px !important;
	min-height: 50px !important;
	direction: ltr !important;
	text-align: left; }


#validationCustomMobile::-webkit-outer-spin-button,
#validationCustomMobile::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; }

#validationCustomMobile[type=number] {
	-moz-appearance: textfield; }


.iti__flag-container {
	direction: ltr !important; }

.textToLeft {
	direction: ltr !important; }

.padit {
	padding-left: 150px !important;
	padding-right: 100px !important; }
