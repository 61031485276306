video {
	height: 100% !important;
	width: 100% !important;
	display: block;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px; }


.videoContainer {
	margin: 0%;
	height: 100% !important;
	width: 100% !important;
	background-color: var(--rf-black); }

.checkBtns {
	all: unset; }


.inputSelecSpan {
	width: 100% !important {
    min-height: 35px;
    align-self: center;
    text-align: left !important;
    min-height: 35px;
    display: block !important;
    cursor: pointer; }
	padding: 5px 8px; }

.inputSelecSpan:hover {
    background: #202124;
    color: var(--rf-text-light); }


#previewVideo {
    min-height: 300px !important; }



#local-cam-container {
    min-height: 250px;
    background-color:#202124 {} }
