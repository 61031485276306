
.f15 {
	font-size: 15px !important; }

.f16 {
	font-size: 16px !important; }

.f18 {
	font-size: 18px !important; }

.f26 {
	font-size: 26px !important; }

.f24 {
	font-size: 26px !important; }

.f20 {
	font-size: 20px !important; }

.f48 {
	font-size: 48px !important; }


.w300 {
	font-weight: 300 !important; }

.w400 {
	font-weight: 400 !important; }

.w500 {
	font-weight: 500 !important; }

.w600 {
	font-weight: 600 !important; }

.w700 {
	font-weight: 700 !important; }

.w800 {
	font-weight: 800 !important; }


.wbold {
	font-weight: bold !important; }


.lh18 {
	line-height: 18px; }

