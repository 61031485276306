#apartment_reserve__step-3__table {
    #apartments_table {
        min-width: 1200px;
        thead {
            tr:nth-child(1) {
                background: var(--rf-title-bg); }
            tr:nth-child(2) {
                background: rgba(var(--rf-title-bg-code), .6); } }
        td {
            opacity: 1 !important; }
        td > div.op05 {
            opacity: 0.5 !important; } }

    .resetFilters {
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        opacity: .6;
        transition: all .3s ease-in-out;
        &:hover {
            opacity: 1; } }

    .mobileTableCollapesed_trigger__overlay {
        position: absolute;
        top: 0;
        bottom: 80px;
        left: 0;
        right: 0; }
    .mobileTableCollapesed_trigger {
        background: none;
        border: none;
        color: var(--rf-main-color);
        font-size: 1.5rem;
        transition: all .3s ease-in-out; }
    .mobileTableCollapesed_trigger:not(.collapsed) {
        transform: rotate(90deg); }

    .collapsedDetails {
        .collapsedDetails__item {} }


    .sortHighlight {
        color: var(--rf-main-color); }


    .pfTableInput {
        max-width: 100px !important; }


    .pfSortColor::after {
        background-color: var(--rf-white) !important; }


    .pfTableFontItem {
        font-size: 12px !important; }


    .health .card {
        border: none !important;
        background-color: transparent !important; }


    #mobileTable {
        display: none; }

    .greyback {
        background-color: var(--rf-body-bg) !important; } }

@media screen and (max-width: 1000px) {
	#desktopTable {
		display: none !important; }

	#mobileTable {
		display: block !important; } }


.tblAllowedBtn {
    color: var(--rf-text-light) !important;
    background-color: var(--rf-main-color) !important;
    border-color: var(--rf-main-color) !important;
    cursor: pointer !important; }

.tblDisbaledBtn {
    color: var(--rf-dark-gray) !important;
    background-color: var(--rf-gray) !important;
    border-color: var(--rf-gray) !important;
    cursor: not-allowed !important; }

