#tour_popup_overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(var(--rf-black-code), .85);
    z-index: 9999999; }
@media (max-width: 1100px) {
    #tour_popup_overlay {
        top: 75px !important; } }

#tour_popup {
    position: fixed;
    top: calc(100vh - 50%);
    left: calc(50% - 125px );
    background: var(--rf-main-color);
    z-index: 99999999;
    width: 250px;
    transition: all .3s ease-in-out;
    opacity: 1;
    color: var(--rf-text-light);

    &:after {
        content: '';
        position: absolute;
        left: calc(50% - 4px);
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        clear: both;
        top: 100%;
        border-top: 8px solid var(--rf-main-color); }
    hr {
        opacity: .1;
        margin: 0.5rem 0; }
    #tour_popup_cta__skip {
        background: transparent;
        color: var(--rf-text-light); }
    #tour_popup_cta__ok {
        background: var(--rf-body-bg);
        color: var(--rf-black); } }


#tour_popup.arrowTop {
    &:after {
        top: -8px !important;
        border-bottom: 8px solid var(--rf-main-color) !important;
        border-top: unset !important; } }

#tour_popup.hidden {
    opacity: 0;
    z-index: -9999999; }
