footer {
    transition: all .3s ease-in-out;
    .footer_image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover; }
    .footer_image {
        min-height: 300px; }
    .footer_image > div > p {
        color: var(--rf-text-light);
        text-shadow: 0 .5rem 0.5rem var(--rf-black) !important; }
    .footer_links {
        display: flex;
        justify-content: center; }

    .footer_bottom_row {
        flex-flow: column;
        min-height: 60px;
        background-color: var(--rf-black);
        justify-content: center; }
    .footer_links,
    .footer_project_logos {
        .list-group-item {
            border: unset !important;
            background-color: unset !important;
            color: var(--rf-text-light);
            white-space: nowrap; } }
    .footer_desclaimer {
        background-color: var(--rf-black); } }
