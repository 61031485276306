// Arrows
.rf-arrow {
    height: 50px;
    width: 50px;
    line-height: 46px;
    border-radius: 50%;
    background: var(--rf-main-color);
    color: var(--rf-text-light);
    text-align: center;
    cursor: pointer;
    font-size: 3rem;
    transition: all .3s ease-in-out;
    display: inline-block;
    &:hover {
        transform: scale(1.1);
        color: var(--rf-text-light); } }
.rf-arrow-disabled {
    background: var(--rf-title-bg);
    cursor: not-allowed;
    color: var(--rf-text-light);
    &:hover {
        transform: unset;
        color: var(--rf-text-light); } }


.slick-arrow {
    position: absolute;
    top: calc(50% - 60px);
    z-index: 99; }

.slick-prev {
    left: 20px; }
.slick-next {
    right: 20px; }
