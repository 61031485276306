.language_selector_desktop {
    button {
        background: unset;
        background: transparent !important;
        border-radius: unset;
        border: unset;
        color: var(--rf-black) !important;
        img {
            height: 20px; }
        &:after {
            color: var(--rf-main-color); }
        &:hover {
            background: transparent; } }
    .dropdown-menu[data-bs-popper] {
        padding: 0;
        a {
            cursor: pointer; } } }

html[dir='rtl'] {
    .language_selector_desktop {
        button {
            padding-right: 15px;
            direction: rtl;
            img {
                margin-left: 10px; }
            &:after {
                margin-right: 8px;
                left: unset !important;
                right: 5px !important;
                vertical-align: 0.15em !important;
                top: 17px; } }
        .dropdown-menu[data-bs-popper] {
            right: unset !important;
            left: 0 !important; } }
    .language_selector_mobile {
        padding-right: 24px;
        padding-left: 12px; } }

html[dir='ltr'] {
    .language_selector_desktop {
        button {
            padding-left: 15px;
            direction: ltr;
            img {
                margin-right: 10px; }
            &:after {
                vertical-align: 0.05em !important;
                margin-left: 8px;
                left: unset !important;
                right: 5px !important;
                top: 17px; } }
        .dropdown-menu[data-bs-popper] {
            left: unset !important;
            right: 0 !important; } }
    .language_selector_mobile {
        padding-left: 24px;
        padding-right: 12px; } }


#main_nav__mobile {
    .language_selector_desktop {
        display: none; } }
#main_nav__desktop {
    .language_selector_mobile {
        display: none !important; } }

.language_selector_mobile {
    .list-group {
        border-radius: 0 !important;
        padding: 0;
        li {
            border: 0 !important;
            a {
                text-decoration: none !important;
                color: var(--rf-scrollbar-thumb);
                transition: all .3s ease-in-out;
                cursor: pointer;
                &:hover {
                    color: var(--rf-black); } }
            a.active {
                color: var(--rf-main-color);
                font-weight: 600; } }
        li.active {
            background: unset !important;
            font-weight: 700;
            text-decoration: underline;
            a {
                color: var(--rf-black) !important; } } } }
