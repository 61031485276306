
html[dir='ltr'] {
    .mde-0 {
        margin-right: 0 !important; }
    .mde-1 {
        margin-right: 0.25rem !important; }
    .mde-2 {
        margin-right: 0.5rem !important; }
    .mde-3 {
        margin-right: 1rem !important; }
    .mde-4 {
        margin-right: 1.5rem !important; }
    .mde-5 {
        margin-right: 3rem !important; }
    .mde-auto {
        margin-right: auto !important; }

    .mds-0 {
        margin-left: 0 !important; }
    .mds-1 {
        margin-left: 0.25rem !important; }
    .mds-2 {
        margin-left: 0.5rem !important; }
    .mds-3 {
        margin-left: 1rem !important; }
    .mds-4 {
        margin-left: 1.5rem !important; }
    .mds-5 {
        margin-left: 3rem !important; }
    .mds-auto {
        margin-left: auto !important; }


    .pds-0 {
        padding-left: 0 !important; }
    .pds-1 {
        padding-left: 0.25rem !important; }
    .pds-2 {
        padding-left: 0.5rem !important; }
    .pds-3 {
        padding-left: 1rem !important; }
    .pds-4 {
        padding-left: 1.5rem !important; }
    @media (min-width: 1150px) {
        .pds-lg-3 {
            padding-left: 1rem !important; } }

    .pde-0 {
        padding-right: 0 !important; }
    .pde-1 {
        padding-right: 0.25rem !important; }
    .pde-2 {
        padding-right: 0.5rem !important; }
    .pde-3 {
        padding-right: 1rem !important; }
    .pde-4 {
        padding-right: 1.5rem !important; }


    .direction_rotate {
        transform: rotate(180deg); }

    .direction_text {
        text-align: left !important; }

    input[type=email] {
        text-align: left !important;
        &::-webkit-input-placeholder {
            text-align: left; }
        &:-moz-placeholder {
            text-align: left; }
        &:placeholder {
            text-align: left; } }

    .form-check {
        padding-right: unset !important;
        padding-left: 1.5em !important;
        input[type=checkbox] {
            float: left !important;
            margin-left: -1.5em;
            margin-top: 5px; } }

    form {
        ::-webkit-input-placeholder {
            text-align: left; }

        -moz-placeholder:  /* Firefox 18- */ {
            text-align: left; }

        ::-moz-placeholder  /* Firefox 19+ */ {
            text-align: left; }

        -ms-input-placeholder: {
            text-align: left; } } }

html[dir='rtl'] {
    .mde-0 {
        margin-left: 0 !important; }
    .mde-1 {
        margin-left: 0.25rem !important; }
    .mde-2 {
        margin-left: 0.5rem !important; }
    .mde-3 {
        margin-left: 1rem !important; }
    .mde-4 {
        margin-left: 1.5rem !important; }
    .mde-5 {
        margin-left: 3rem !important; }
    .mde-auto {
        margin-left: auto !important; }


    .mds-0 {
        margin-right: 0 !important; }
    .mds-1 {
        margin-right: 0.25rem !important; }
    .mds-2 {
        margin-right: 0.5rem !important; }
    .mds-3 {
        margin-right: 1rem !important; }
    .mds-4 {
        margin-right: 1.5rem !important; }
    .mds-5 {
        margin-right: 3rem !important; }
    .mds-auto {
        margin-right: auto !important; }


    .pds-0 {
        padding-right: 0 !important; }
    .pds-1 {
        padding-right: 0.25rem !important; }
    .pds-2 {
        padding-right: 0.5rem !important; }
    .pds-3 {
        padding-right: 1rem !important; }
    .pds-4 {
        padding-right: 1.5rem !important; }

    @media (min-width: 1150px) {
        .pds-lg-3 {
            padding-right: 1rem !important; } }

    .pde-0 {
        padding-left: 0 !important; }
    .pde-1 {
        padding-left: 0.25rem !important; }
    .pde-2 {
        padding-left: 0.5rem !important; }
    .pde-3 {
        padding-left: 1rem !important; }
    .pde-4 {
        padding-left: 1.5rem !important; }


    .direction_text {
        text-align: right !important; }

    input[type=email] {
        text-align: left !important;
        &::-webkit-input-placeholder {
            text-align: right; }
        &:-moz-placeholder {
            text-align: right; }
        &:placeholder {
            text-align: right; } }

    .form-check {
        padding-left: unset !important;
        padding-right: 1.5em !important;
        input[type=checkbox] {
            float: right !important;
            margin-right: -1.5em;
            margin-top: 5px; } }
    form {
        ::-webkit-input-placeholder {
            text-align: right; }

        -moz-placeholder:  /* Firefox 18- */ {
            text-align: right; }

        ::-moz-placeholder  /* Firefox 19+ */ {
            text-align: right; }

        -ms-input-placeholder: {
            text-align: right; } } }
