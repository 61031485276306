#apartment_reserve__step-6 {
    .bg-image {
        img:first-child {
            max-height: calc(100vh - 190px); } }

    #paymentImg-sticky-wrapper {
        padding: 0;
        width: 40% !important;
        &:not(.is-sticky) {
            .step_side_img {
                width: unset !important; } } }
    .stepCon {
        max-width: 60%; } }
@media (max-width: 1400px) {
    #apartment_reserve__step-6 {
        #paymentImg-sticky-wrapper {
            display: none !important; } }
    .stepCon {
        max-width: unset !important; } }
@media (max-width: 550px) {
    #apartment_reserve__step-6 {
        .apartment_list__item {
            flex-flow: column-reverse;
            #paymentModal {
                img {
                    width: 100%; } } } } }

.blknwhite {
    filter: grayscale(100%); }

#paymentForm iframe {
    max-height: 35px !important; }

iframe body {
    display: flex !important;
    justify-content: center !important; }

iframe  > body > input {
    align-self: center; }


#paymentForm iframe::placeholder {
    text-align: center !important; }

#fullName,#sum {
    min-height: 50px !important; }


.paymentFieldName {
    font-size: 16px !important;
    text-align: center; }

.paymentFieldName::placeholder {
    text-align: center; }

.paymentFieldName:focus::placeholder {
    opacity: 0; }


.modal-header {
    justify-content: center !important; }

.paymentLabel {
    font-weight: 500 !important;
    font-size: 18px !important; }

#resSpan {
    direction: rtl; }
