#home_hero {
    height: 80vh;
    overflow: hidden;
    .hero_content {
        .hero_content__box {
            background-color: rgba(var(--rf-dark-bg-code), .8);
            color: var(--rf-text-light); } }
    .MainLoader {
        opacity: .2; } }



@media (max-width: 1100px) {
    #home_hero {
        .hero_content {
            .hero_content__box {
                display: flex;
                flex-flow: column;
                align-items: center;
                width: 100%; }
            .hero_content__box  div.d-grid {
                width: 100%; } } } }

.mainImage {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    z-index: 0;
    position: absolute;
    height: 80%;
    object-fit: cover; }
