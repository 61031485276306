#backToTop_button {
    position: fixed;
    bottom: 10px;
    transition: all .3s ease-in-out;
    right: 10px;
    width: 50px;
    height: 50px;
    color: var(--rf-text-light);
    z-index: 999999;
    background: var(--rf-main-color);
    border-radius: 50%;
    border: 0;
    line-height: 40px;
    font-size: 28px;
    display: flex;
    line-height: 46px;
    justify-content: center;
    padding: 0; }
