form {
    .input-group-day-select {
        input[type=radio] + label {
            background: var(--rf-white);
            display: flex;
            flex-flow: column;
            justify-content: center;
            color: var(--rf-dark-gray);
            border: 2px solid rgba(var(--rf-dark-gray-code),.2);
            transition: all .3s ease-in-out;
            span {
                color: var(--rf-black);
                transition: all .3s ease-in-out;
                font-weight: bold; } }
        input[type=radio]:focus + label,
        input[type=radio]:hover + label {
            border: 2px solid var(--rf-dark-gray); }

        input[type=radio]:checked + label {
            border: 2px solid var(--rf-main-color);
            background-color: var(--rf-main-color);
            color: var(--rf-text-light) !important;
            span {
                color: var(--rf-text-light); } } }

    .schedule_meeting__change_week {
        a {
            text-decoration: none;
            cursor: pointer;
            transition: all .3s ease-in-out;
            &.pfIcon {
                cursor: not-allowed;
                &:hover {
                    transform: unset !important; } }
            &:hover {
                transform: scale(1.05); }
            i {
                line-height: 23px;
                height: 23px;
                width: 23px;
                border-radius: 50%;
                background: var(--rf-main-color);
                color: var(--rf-text-light);
                display: inline-block;
                text-align: center;
                font-size:  1.3rem; } } }

    .input-group-day-hour {
        flex-shrink: unset;
        width: unset;
        input[type=radio] + label {
            background: var(--rf-white);
            border: 2px solid rgba(var(--rf-dark-gray-code), .2);
            transition: all .3s ease-in-out;
            span {
                color: var(--rf-black);
                font-weight: bold; } }
        input[type=radio]:focus + label,
        input[type=radio]:hover + label {
            border: 2px solid var(--rf-dark-gray); }

        input[type=radio]:checked + label {
            border: 2px solid var(--rf-main-color);
            background-color: var(--rf-main-color);
            color: var(--rf-text-light) !important; } } }

#radioButtonsContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    .input-group-day-select {
        input[type=radio] + label {
            width: 6.8vw;
            height: 6.8vw; } } }

@media (max-width: 1550px) {
    #radioButtonsContainer {
        grid-template-columns: repeat(4, 1fr) !important;
        grid-template-rows: repeat(2, 1fr);
        .input-group-day-select {
            input[type=radio] + label {
                width: 8.5vw;
                height: 8.5vw; } } } }


@media (max-width: 1250px) {
    #radioButtonsContainer {
        grid-template-columns: repeat(3, 1fr) !important;
        grid-template-rows: repeat(2, 1fr);
        .input-group-day-select {
            input[type=radio] + label {
                width: 11vw;
                height: 11vw; } } } }

@media (max-width: 1000px) {
    #radioButtonsContainer {
        grid-template-columns: repeat(7, 1fr) !important;
        grid-template-rows: repeat(1, 1fr);
        .input-group-day-select {
            input[type=radio] + label {
                width: 12.5vw;
                height: 12.5vw; } } } }


@media (max-width: 850px) {
    #radioButtonsContainer {
        grid-template-columns: repeat(6, 1fr) !important;
        grid-template-rows: repeat(2, 1fr);
        .input-group-day-select {
            input[type=radio] + label {
                width: 14.5vw;
                height: 14.5vw; } } } }


@media (max-width: 750px) {
    #radioButtonsContainer {
        grid-template-columns: repeat(5, 1fr) !important;
        grid-template-rows: repeat(2, 1fr);
        .input-group-day-select {
            input[type=radio] + label {
                width: 17.5vw;
                height: 17.5vw; } } } }



@media (max-width: 580px) {
    #radioButtonsContainer {
        grid-template-columns: repeat(4, 1fr) !important;
        grid-template-rows: repeat(2, 1fr);
        .input-group-day-select {
            input[type=radio] + label {
                width: 21.5vw;
                height: 21.5vw;
                font-size: .8rem !important; } } } }



@media (max-width: 480px) {
    #radioButtonsContainer {
        grid-template-columns: repeat(3, 1fr) !important;
        grid-template-rows: repeat(2, 1fr);
        .input-group-day-select {
            input[type=radio] + label {
                width: 27vw;
                height: 27vw; } } } }
