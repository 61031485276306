#apartment_reserve__stepCon {
    canvas {
        width: 700px !important; }
    .bg-image {
        img:first-child {
            max-height: calc(100vh - 150px); } }
    #contractImg {
        img {
            max-width: 520px;
            min-height: 750px; } }
    #contractImg-sticky-wrapper {
        padding: 0;
        width: 40% !important;
        &:not(.is-sticky) {
            .step_side_img {
                width: unset !important; } } }
    .stepCon {
        max-width: 60%; }

    .SignatureCanvas__close {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; } }
@media (max-width: 1550px) {
    #contractImg-sticky-wrapper {
        display: none !important; }
    #apartment_reserve__stepCon {
        .stepCon {
            max-width: unset !important; } } }
