
@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		-webkit-opacity: 0; }
	40% {
		-webkit-transform: scale(1.0);
		-webkit-opacity: 1; } }

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 0; }
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
		opacity: 1; } }

.sideImageLG {
	width: 80% !important;
	text-align: center; }

.sideImageLG > div {
	width: 20px;
	height: 20px;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.sideImageLG .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s; }

.sideImageLG .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s; }
