.pfEmailInput {
    max-width: 76% !important;
    align-self: center; }


.pfPhonePlaceholder::placeholder {
    align-self: left !important;
    float:none !important {}
    text-align: center; }


#login-form__step1 {
    min-height: 100vh !important; }

.f36 {
    font-size: 36px !important; }

#loginFormContent-step1 {
    margin-top: 5% !important; }


.loginFixWidth {
    max-width: 40% !important; }
