@keyframes appear {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }



.appear {
    animation: 0.2s appear ease-in-out; }


.invalidField {
    height: 15px !important;
    width: auto;
    color: var(--rf-text-error); }


.feedbackWrapper {
    height: 25px; }


.d-left {
    direction: ltr !important; }

.costumeCol {
    min-width: 82% !important;
    max-width: 82% !important; }

.costumeCol2 .row {
    margin-bottom: 0 !important; }

@media (min-width:250px) and (max-width: 767px) {
	.costumeCol {
        min-width: 100% !important;
        max-width: 100% !important; } }

