#findAgentMobile_trigger__container {
    transition: all .3s ease-in-out;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 99999;
    min-height: 60px;
    transition: all .3s ease-in-out;

    #findAgentMobile_notfound {
        a {
            color: var(--rf-text-light); } }
    #findAgentMobile_searching {
        height: 100%;
        min-height: 60px;
        // #timeText_m
        //     text-align: center
        .sideImageLG {
            transform: scale(.5);
            div {
                background-color: var(--rf-white); } }
        #timeBar {
            margin-top: 10px;
            height: 4px;
            width: 100%;
            background: rgba(var(--rf-white-code), .4);
            display: block; }
        #searchTimeBar {
            margin-top: 0 !important; }
        #progBar_m {
            height: 4px;
            background: var(--rf-white);
            display: block; }

        .lds-default {
            transform: scale(.5); } } }

#progBar_m {
    height: 4px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }

#hideMobile_FindAgent {
    top: -24px;
    cursor: pointer;
    position: absolute;
    background: var(--rf-white);
    text-align: center;
    width: 50px;
    height: 25px;
    padding-top: 3px;
    left: calc(50% - 25px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: all .3s ease-in-out;
    i {
        display: inline-block;
        transition: all .3s ease-in-out; } }

#hideMobile_FindAgent.withProgBar {
    top: -25px; }
#hideMobile_FindAgent.minimized {
    i {
        transform: rotate(180deg); } }


html[dir='rtl'] {
    #findAgentMobile_trigger {
        width: 100%;
        padding: 11px 12px;
        font-size: 1.5rem;
        font-weight: 500;
        border-radius: 0 !important;
        img {
            margin-left: 1rem; } } }


html[dir='ltr'] {
    #findAgentMobile_trigger {
        width: 100%;
        padding: 11px 12px;
        font-size: 1.5rem;
        font-weight: 500;
        border-radius: 0 !important;
        img {
            margin-right: 1rem; } } }
#loadingVideoModal {
    width: 100%; }

@media (min-width: 1100px) {
    #findAgentMobile_trigger__container,
    #minimizeVideo {
        display: none !important; } }

@media (max-width: 1100px) {
    #agentFormBack {
        display: none !important; }
    #meetingVideoMobile__container {
        .videoWrapper {
            overflow: hidden;
            transition: height .3s ease-in-out;
            height: 100%; }
        .minimized {
            height: 0 !important; } }
    #minimizeVideo {
        position: absolute;
        top: -170px;
        z-index: 999;
        left: 10px;
        background: none;
        border: 0;
        transition: all .3s ease-in-out;
        font-size: 1.25rem; }
    #minimizeVideo.targetMinimized {
        top: 11px;
        opacity: 1;
        font-size: 23px; }

    #meetingvideocontainer {
        width: unset !important;
        border-radius: 0 !important;
        box-shadow: unset !important;

        #remoteVideo {
            height: 170px !important;
            width: 226px !important;
            margin: 0 auto; }
        #loadingVideoModal {
            width: 226px !important; }

        #localVideo {
            height: 58px !important;
            width: unset !important;
            left: calc(50% - 115px) !important; }

        .videoCom {
            margin: 0 !important;
            width: 100%;
            border-radius: 0 !important; }
        #meetingTimerBar,
        #videoMeeting_userInfo,
        #toast_container, {
            display: none !important; } }



    #videoMenuControlsMobile__container {
        position: fixed;
        z-index: 999999;
        bottom: 0;
        min-height: 60px;
        display: flex;
        align-items: center; } }
